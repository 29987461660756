<template>
  <ul class="ul-user-mem-scroll cust-pd-5">
    <li class="friend-suggestion flex px-2 items-center mb-5">
      <div class="ml-3 w-full">
        <p class="user-text-md">Select All</p>
        <div class="ml-2 w-full"></div>
      </div>
      <label class="container container_groups_radio">
        <input
          type="checkbox"
          v-model="selectAll"
          @change="selectAllAction()"
          :checked="selectAll"
        />
        <span class="checkmark"></span>
      </label>

      <div></div>
    </li>
    <li v-for="user in members" :key="user.UID" class="friend-suggestion">
      <InvitedMemberItem :user="user" />
    </li>
  </ul>
</template>

<script>
import InvitedMemberItem from "./InvitedMemberItem";
export default {
  components: {
    InvitedMemberItem,
  },
  props: {
    members: {
      type: Array,
      default: () => [],
    },
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectAll: false,
    };
  },
  watch: {
    isSidebarActive(val) {
      if (!val) {
        this.selectAll = false;
      }
    },
  },
  methods: {
    selectAllAction() {
      if (this.selectAll) {
        this.checkAll();
      } else {
        this.unCheckAll();
      }
    },
    checkAll() {
      this.$store.dispatch("userInvitation/selectAllUsers");
    },
    unCheckAll() {
      this.$store.dispatch("userInvitation/unselectAllUsers");
    },
  },
};
</script>


