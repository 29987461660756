<template>
  <vs-chip closable class="grp-userselect" close-icon="close">
    <vs-avatar class="bg-primary" :src="image" :text="user.name" />
    {{ user.name }}
  </vs-chip>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    magittUrl() {
      return this.baseUrlMagitt;
    },
    image() {
      return this.user.image ? this.magittUrl + this.user.image : null;
    }
  }
};
</script>
