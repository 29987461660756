<template>
  <div>
    <vs-avatar
      :class="{
        '': !userImage,
        'bg-white': userImage,
      }"
      :src="userImage != null ? magittUrl + userImage : null"
      :text="name"
      alt="content-img"
      class="imgRadius-md"
      :size="size"
      @click="showProfile(userID)"
    />
    <img
      :src="magittUrl + userImage"
      @error="handleImageError"
      class="hidden"
    />
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: "User",
    },
    size: {
      type: String,
      default: "32px",
    },
    userID: {
      type: Number,
      default: null,
    },
  },
  computed: {
    magittUrl() {
      return this.baseUrlMagitt;
    },
    name() {
      return this.text != null ? this.text.trim() : "User";
    },
    userImage() {
      return this.image;
    },
  },
  data() {
    return {
      defaultImage: "uploads/defaultImages/default_post.png",
    };
  },
  methods: {
    showProfile(userid) {
      if (userid != null) {
        this.$store.state.memberId = userid;
        this.$router.push("/community-user-profile").catch(() => {});
      }
    },
    handleImageError() {
      this.$emit("imageNotFound");
    },
  },
};
</script>
