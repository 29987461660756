<template>
  <div
    class="flex px-2 items-center cursor-pointer mb-2"
    @click.stop="selectAction(user)"
    v-if="!isUserSelected"
  >
    <div class="media-left">
      <UserAvatar
        :image="user.image"
        :text="user.name"
        :size="'35px'"
        @imageNotFound="user.image = null"
      />
    </div>

    <div class="row media-body ml-3 mt-1 w-full">
      <p class="user-text-md">
        {{ user.name }}
      </p>
    </div>
    <label class="container container_groups_radio">
      <input
        type="checkbox"
        v-model="user.isSelected"
        @change.stop="selectAction(user)"
        checked="checked"
      />
      <span class="checkmark"></span>
    </label>
  </div>
</template>

<script>
import UserAvatar from "@/Core/Views/CommonPlugins/user-avatar/UserAvatar";
export default {
  components: {
    UserAvatar,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isUserSelected() {
      return this.user.isSelected;
    },
  },
  methods: {
    selectAction(user) {
      //do something
      this.$store.dispatch("userInvitation/selectUser", user);
    },
  },
};
</script>
