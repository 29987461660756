<template>
  <div>
    <div
      class="con-img m-2"
      v-for="user in selectedMembers"
      :key="user.UID"
      style="display: inline-flex; position: inherit;"
      @click="removeUserFromGroup(user)"
    >
      <SelectedMemberItem :user="user" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SelectedMemberItem from "./SelectedMemberItem";
export default {
  components: {
    SelectedMemberItem
  },
  computed: {
    ...mapGetters("userInvitation", {
      selectedMembers: "selectedUsers"
    })
  },
  methods: {
    removeUserFromGroup(user) {
      this.$store.dispatch("userInvitation/unselectUser", user);
    }
  }
};
</script>
