<template>
  <div>
    <slot name="searchBar" />
  </div>
</template>

<script>
export default {
  props: {
    searchTerm: {
      type: String,
      default: "",
    },
    dataArray: {
      type: Array,
      default: () => [],
    },
    keys: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    searchTerm(val) {
      if (!val.length) return;
      this.searchResults();
    },
  },
  methods: {
    searchResults() {
      if (this.searchTerm.length > 1) {
        // this.$search(this.searchTerm, this.dataArray, { keys: this.keys }).then(
        //   results => {
        //     this.$emit("searchResult", results);
        //   }
        // );
        console.log("dfsdsdf",this.searchTerm)
        const regex = new RegExp(`.*${this.searchTerm}.*`, "i");
        console.log(regex,"regexregex")
        console.log(this.dataArray,"dataArraydataArray")
        const results=this.dataArray.filter((item,index)=>{
          const result =  item.createdBy.toLowerCase().includes(this.searchTerm.toLowerCase()) || item.ipAddress.toLowerCase().includes(this.searchTerm.toLowerCase())
       return result
        })
        // const  = this.dataArray.filter(({ usersDetails_UserName }) =>
        
        //   usersDetails_UserName.createdBy.match(regex)
        // );
        console.log(results,"resultsresultsresults")
        this.$emit("searchResult", results);
      }
    },
  },
};
</script>
