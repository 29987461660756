<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="sidebarx"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="flex px-4 sidebar-topfixpart bg-primary">
      <feather-icon
        icon="ChevronLeftIcon"
        @click="isSidebarActiveLocal = false"
        class="cursor-pointer"
      ></feather-icon>
      <vs-button class="h2-class pd-10 cust-remove-boxsh">{{
        inviteSingleUser ? "INVITE " : "INVITE ALL"
      }}</vs-button>
    </div>

    <div class="Main-inviteallsection mt-16">
      <div class="input-invite-sec pd-10">
        <vs-textarea
          class="inputx w-full"
          placeholder="Email Subject"
          v-model="inviteSubject"
          height="90px"
          rows="3"
        />
      </div>
      <div class="input-invite-sec pd-10">
        <vs-textarea
          class="inputx w-full"
          placeholder="Message Body"
          v-model="inviteContent"
          height="290px"
          rows="12"
        />
      </div>
      <vs-button
        color="primary"
        type="filled"
        class="w-full block addPost-sent-invites h2-class"
        @click="sendInvites"
      >
        <span class="h3-upp-class h2-class">SEND</span>
      </vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import { mapGetters } from "vuex";
import "@/Core/Views/Commoncss/componentCss/allSidebar.css";
import EmailSMSMixin from "@/Client/Kotak/mixins/EmailSMSMixin";
import validationMixin from "@/Core/mixins/validationMixin";
import * as emailer from "@/Client/Kotak/emailer/inviteEmailer.js";

export default {
  mixins: [EmailSMSMixin, validationMixin],
  components: {},
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    inviteSingleUser: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isSidebarActive(val) {
      if (!val) {
        this.inviteContent = "";
        this.inviteSubject = "";
      }
    },
    $route(to, from) {
      if (to !== from) {
        this.isSidebarActiveLocal = false;
        this.$emit("closeSidebar");
      }
    },
    inviteSubject(val) {
      this.scriptValidation(val, "inviteSubject");
    },
    inviteContent(val) {
      this.scriptValidation(val, "inviteContent");
    },
  },
  data() {
    return {
      inviteContent: "",
      inviteSubject: "",
      inviteLink: "",
    };
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
        }
      },
    },
    ...mapGetters("userInvitation", {
      allMembers: "unselectedUsers",
      allMobiles: "selectedUserMobiles",
      allEmails: "selectedUserEmails",
    }),
    isMessageValid() {
      return this.inviteContent != "";
    },
  },
  methods: {
    async geterror() {
      this.$swal({
        icon: "warning",
        title: "Please fill all field",
        timer: 2000,
      });
    },
    sendInvites() {
      if (this.inviteContent == "" || this.inviteSubject == "") {
        return this.geterror();
      }
      this.$swal({
        icon: "success",
        title: "How would you like to invite",
        html: `
                  <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" id="checkbox1">
                      <label class="form-check-label" for="checkbox1">
                          SMS
                      </label>
                      <input class="form-check-input" type="checkbox" value="" id="checkbox2">
                      <label class="form-check-label" for="checkbox2">
                          Email
                      </label>
                  </div>
                `,
        focusConfirm: false,
        confirmButtonText: "Send",
        cancelButtonText: "Cancel",
        showConfirmButton: true,
        showCancelButton: true,
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          const isSMS = document.getElementById("checkbox1").checked;
          const isEmail = document.getElementById("checkbox2").checked;

          if (isSMS || isEmail) {
            const link = await this.$store.dispatch("shortURL/getLastURL");
            this.inviteLink = link.short;

            this.invite(isSMS, isEmail);
          }
        }
      });
    },
    invite(isSMS, isEmail) {
      if (this.inviteSingleUser && isSMS) {
        this.sendSMS(
          `${this.inviteContent} - ${this.inviteFullPath(this.inviteLink)}`,
          [this.data.mobile]
        );
      }
      if (this.inviteSingleUser && isEmail) {
        this.sendEmail(
          emailer.emailHTML(
            this.inviteFullPath(this.inviteLink),
            this.inviteContent
          ),
          this.inviteSubject,
          [this.data.email]
        );
      }
      if (!this.inviteSingleUser && isSMS) {
        this.sendSMS(
          `${this.inviteContent} - ${this.inviteFullPath(this.inviteLink)}`,
          this.allMobiles
        );
      }
      if (!this.inviteSingleUser && isEmail) {
        this.sendEmail(
          emailer.emailHTML(
            this.inviteFullPath(this.inviteLink),
            this.inviteContent
          ),
          this.inviteSubject,
          this.allEmails
        );
      }

      this.$swal("", "Invitations Sent!", "success");
      this.$emit("closeAllSidebar");
    },
    inviteFullPath(short) {
      return `${window.document.location.origin}/posts/${short}`;
    },
  },
};
</script>
