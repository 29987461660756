<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="">
      <div class="flex px-4 sidebar-topfixpart bg-primary">
        <feather-icon
          icon="ChevronLeftIcon"
          @click.stop="isSidebarActiveLocal = false"
          class="cursor-pointer"
        ></feather-icon>
        <vs-button class="h2-class pd-10 cust-remove-boxsh"
          >INVITE MEMBERS</vs-button
        >
      </div>
      <div>
        <div
          class="panel-body smp_panel_two invitation-memr-h h3-class mt-16 pl-0"
        >
          <SelectedMembersList />
          <MemberSearch
            :searchTerm="searchTerm"
            :dataArray="memberList"
            :keys="searchKey"
            @searchResult="filterUsers"
          >
            <template slot="searchBar">
              <div
                class="mb-4 invite-disc-search mob-view-serh ml-3 mr-3 flex items-cente"
                style="border-radius:0px !important;"
              >
                <vs-input
                  icon-pack="feather"
                  icon="icon-search"
                  icon-after
                  vs-placeholder="Nombre"
                  class="w-full"
                  v-model="searchTerm"
                />
              </div>
            </template>
          </MemberSearch>
          <InvitedMemberList
            :members="members"
            :isSidebarActive="isSidebarActive"
          />
        </div>
        <div class="memb-pagin">
          <vs-pagination
            :total="membersCount"
            v-model="membersPage"
          ></vs-pagination>
        </div>
        <vs-button
          color="primary"
          type="filled"
          class="w-full block addPost-sent-invites h2-class"
          :disabled="isSelectedUsersEmpty"
          @click="sendInvites"
        >
          <span class="h3-upp-class h2-class">SEND</span>
        </vs-button>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import { mapGetters } from "vuex";
import "@/Core/Views/Commoncss/componentCss/allSidebar.css";
import MemberSearch from "@/Core/Views/CommonPlugins/member-search/MemberSearch";
import InvitedMemberList from "./InvitedMemberList";
import SelectedMembersList from "./SelectedMembersList";
export default {
  components: {
    MemberSearch,
    InvitedMemberList,
    SelectedMembersList
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Array,
      default: () => {}
    }
  },
  watch: {
    isSidebarActive(val) {
      if (!val) {
        this.$store.dispatch("userInvitation/resetUsers");
      } else {
        this.memberList = this.allMembers;
      }
    },
    $route() {
      this.isSidebarActiveLocal = false;
      this.$emit("closeSidebar");
    }
  },
  data() {
    return {
      memberList: [],
      token: {},
      membersSize: 8,
      membersPage: 1,
      searchTerm: "",
      searchResults: [],
      searchKey: ["name"]
    };
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
        }
      }
    },
    membersCount() {
      let l = 0,
        s = this.membersSize;
      if (this.searchTerm.length > 1) {
        l = this.searchResults.length;
      } else {
        l = this.allMembers.length;
      }
      return Math.ceil(l / s);
    },
    members() {
      const start = (this.membersPage - 1) * this.membersSize,
        end = start + this.membersSize;
      if (this.searchTerm.length > 1) {
        return this.searchResults.slice(start, end);
      } else {
        return this.allMembers.slice(start, end);
      }
    },
    ...mapGetters("userInvitation", {
      allMembers: "unselectedUsers",
      selectedMembers: "selectedUsers"
    }),
    isSelectedUsersEmpty() {
      if (this.selectedMembers.length > 0) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    filterUsers(result) {
      this.searchResults = result;
      if (this.searchResults.length > 0) {
        this.membersPage = 1;
      }
    },
    sendInvites() {
      this.$emit("openSendInviteSidebar");
    }
  }
};
</script>

<style scoped>
.invitation-memr-h {
  height: 79vh;
  overflow-y: auto;
}
.invitation-memr-h::-webkit-scrollbar {
  width: 0;
}
</style>
