<!-- =========================================================================================
  File Name: ECommerceShop.vue
  Description: eCommerce Shop Page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <vs-popup class="holamundo" title="Edit" :active.sync="popupActive">
      <div class="smp_social_modal_width px-5">
        <div class="pop-up">
          <!-- <div class="row mt-5">
            <vs-input
              name="username"
              v-validate="'required'"
              class="w-full"
              v-model="selecteduser.name"
              label-placeholder="Name"
            ></vs-input>
          </div> -->

          <!-- <div class="row mt-8">
            <vs-input
              name="mobile"
              v-validate="'required'"
              class="w-full"
              v-model="selecteduser.newmobile"
              label-placeholder="Mobile"
            ></vs-input>
          </div> -->
          <!--    <div class="row mt-8">
            <vs-input
              name="email"
              v-model="selecteduser.email"
              v-validate="'required'"
              class="w-full"
              label-placeholder="Email"
            ></vs-input>
          </div> -->

          <div v-if="selecteduser.newrole !== 'Reader'" class="row mt-3">
            <vs-input
              name="ldapid"
              v-validate="'required'"
              class="w-full"
              v-model="selecteduser.ldapid"
              label-placeholder="LDAP ID"
              @keydown="validateldap"
              @change="validateldap"
              maxlength="15"
            ></vs-input>
            <span
              v-if="selecteduser.ldapid.length < 8"
              class="text-danger text-sm"
            >
              {{ ldaperror }}</span
            >
          </div>
          <div class="w-full flex items-center my-5 gap-3">
            <div class="">
              <vs-radio
                vs-name="role"
                v-model="selecteduser.newrole"
                vs-value="community-manager"
                >Community Manager</vs-radio
              >
            </div>
            <div class="">
              <vs-radio
                vs-name="role"
                v-model="selecteduser.newrole"
                vs-value="channel-admin"
                >Channel Admin</vs-radio
              >
            </div>
            <div class="">
              <vs-radio
                vs-name="role"
                v-model="selecteduser.newrole"
                vs-value="Reader"
                >Reader</vs-radio
              >
            </div>
          </div>
          <div class="row mt-4">
            <vs-button class="smp_btn_add bg-primary" @click="updateuserinfo()">
              Send Request
            </vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
    <div class="m-4">
      <div class="algolia-header mb-4">
        <div class="flex md:items-end items-center justify-between flex-wrap">
          <div>
            <feather-icon
              class="inline-flex lg:hidden cursor-pointer mr-4"
              icon="MenuIcon"
              @click.stop="toggleFilterSidebar"
            />
            <p
              class="lg:inline-flex hidden font-semibold algolia-filters-label"
            >
              Filters
            </p>
          </div>
          <div>
            <p class="font-semibold md:block hidden">
              {{ totalResults }} results found
            </p>
          </div>
          <div class="flex justify-between items-end">
            <div class="flex flex-wrap">
              <div></div>
            </div>
          </div>
        </div>
      </div>

      <div id="algolia-content-container " class="relative clearfix">
        <div class="flex lg:flex-row md:flex-row flex-col">
          <vs-card
            class="viewprof-cateegorycard"
            parent="#algolia-content-container"
            :click-not-close="clickNotClose"
            :hidden-background="clickNotClose"
            v-model="isFilterSidebarActive"
          >
            <div class="p-3 filter-container">
              <div class="flex">
                <vs-button
                  color="success"
                  class="w-full mb-base mr-3"
                  to="/pages/excelinvites"
                  >Invite Users</vs-button
                >

                <vs-button
                  color="dark"
                  v-show="category == 'Invited users'"
                  class="w-full mb-base"
                  @click.stop="inviteAllMembers"
                  >Invite All</vs-button
                >
              </div>
              <!--invite all sidebar-->
              <send-invitation-sidebar
                :isSidebarActive="inviteall"
                @closeSidebar="toggleSendInvitationSidebar"
                @closeAllSidebar="closeAllInviteSidebar"
                :inviteSingleUser="inviteSingleUser"
                :data="invitedUser"
              />

              <invited-members-select-sidebar
                :isSidebarActive="openInvitedMembersSidebar"
                @closeSidebar="toggleInvitedMembersSidebar"
                @openSendInviteSidebar="toggleSendInvitationSidebar(true)"
                :data="members"
              />

              <!--end invite all sidebar-->
              <div>
                <ul>
                  <li
                    v-for="item in categories"
                    :key="item.value"
                    class="removelist"
                  >
                    <vs-radio
                      v-if="
                        item.value == 'Membership Request' &&
                        check('superadmin')
                      "
                      @change="filterUsers"
                      v-model="category"
                      :vs-value="item.value"
                      >{{ item.text }}</vs-radio
                    >
                    <vs-radio
                      v-else-if="item.value !== 'Membership Request'"
                      @change="filterUsers"
                      v-model="category"
                      :vs-value="item.value"
                      >{{ item.text }}</vs-radio
                    >
                  </li>
                </ul>
                <vs-button type="border" class="w-full" @click="removeFilter"
                  >Remove Filters</vs-button
                >
              </div>
            </div>
          </vs-card>

          <!-- RIGHT COL -->
          <div :class="{ 'sidebar-spacer-with-margin': clickNotClose }">
            <!-- SEARCH BAR -->

            <!-- SEARCH RESULT -->
            <div>
              <div class="relative mb-8 viewall-category">
                <vs-input
                  class="w-full vs-input-shadow-drop vs-input-no-border d-theme-input-dark-bg"
                  placeholder="Search here"
                  v-model="searchTerm"
                  size="large"
                  @keydown="searchResults"
                />

                <div
                  class="absolute top-0 right-0 py-4 px-6"
                  v-if="searchTerm.length == 0"
                >
                  <feather-icon icon="SearchIcon" svgClasses="h-6 w-6" />
                </div>

                <div
                  class="absolute top-0 right-0 py-4 px-6"
                  v-if="searchTerm.length > 0"
                  @click="resetSearchTerm"
                >
                  <feather-icon
                    icon="XIcon"
                    svgClasses="h-6 w-6 cursor-pointer"
                  />
                </div>
              </div>
            </div>
            <!-- <ais-hits> -->
            <div class="">
              <!-- GRID VIEW -->
              <template v-if="currentItemView == 'item-grid-view'">
                <div class="items-grid-view vx-row match-height">
                  <div
                    class="vx-col lg:w-1/3 sm:w-1/2 w-full cursor-pointer"
                    v-for="item in members"
                    :key="item.objectID"
                  >
                    <item-grid-view :item="item">
                      <!-- SLOT: ACTION BUTTONS -->
                      <template slot="status-buttons">
                        <div class="flex-wrap">
                          <div class="bottm-parent-btn flex">
                            <div
                              v-if="check('admin') && item.status == 0"
                              class="item-view-primary-action-btn bg-success cust-small-btn mr-3 flex flex-grow items-center justify-center cursor-pointer"
                              @click="updateUserStatus(item.objectID, 1)"
                            >
                              <span class="text-white fontszie-smallbtn">
                                Approve</span
                              >
                            </div>
                            <div
                              v-if="check('admin') && item.status == 0"
                              class="item-view-primary-action-btn bg-danger cust-small-btn mr-3 flex flex-grow items-center justify-center cursor-pointer"
                              @click="updateUserStatus(item.objectID, 2)"
                            >
                              <span class="text-white fontszie-smallbtn">
                                Reject</span
                              >
                            </div>
                          </div>
                        </div>
                      </template>
                      <template slot="action-buttons">
                        <div class="flex-wrap">
                          <!-- PRIMARY BUTTON: ADD TO WISH LIST -->
                          <div class="bottm-parent-btn flex">
                            <div
                              v-if="check('admin') && !item.isOwner"
                              class="item-view-primary-action-btn p-1 flex flex-grow items-center justify-center cursor-pointer"
                              @click="
                                updateUserBlockStatus(
                                  item.objectID,
                                  item.blockStatus,
                                  item.name
                                )
                              "
                            >
                              <span class="text-sm font-semibold ml-2">
                                {{
                                  item.blockStatus ? "UNBLOCK" : "BLOCK3  "
                                }}</span
                              >
                            </div>

                            <div
                              v-if="
                                check('superadmin') &&
                                !item.isNormalUser &&
                                !item.isOwner
                              "
                              class="item-view-primary-action-btn p-1 flex flex-grow items-center justify-center cursor-pointer"
                              @click="
                                updateManagerStatus(
                                  item.UID,
                                  item.name,
                                  item.isManager
                                )
                              "
                            >
                              <span class="text-sm font-semibold ml-2">
                                {{
                                  item.isManager
                                    ? "REMOVE COMMUNITY MANAGER"
                                    : "MAKE COMMUNITY MANAGER"
                                }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </template>
                    </item-grid-view>
                  </div>
                </div>
              </template>

              <!-- LIST VIEW -->
              <template v-else>
                <div
                  class="items-list-view match-height"
                  v-for="item in members"
                  :key="item.objectID"
                >
                  <item-list-view :item="item">
                    <template slot="status-buttons">
                      <div class="flex-wrap mb-2">
                        <div class="flex">
                          <!-- <vs-chip
                            color="success"
                            class="ml-4"
                            v-if="item.status == 1"
                            >Active</vs-chip
                          > -->

                          <div
                            v-if="
                              check('superadmin') &&
                              item.status == 2 &&
                              !item.showApprove
                            "
                            :class="{
                              'bg-success': item.blockStatus,
                              'bg-danger': !item.blockStatus,
                            }"
                            class="item-view-primary-action-btn ml-3 cust-small-btn flex flex-grow items-center justify-center cursor-pointer"
                            @click="approveRejectedUserAlert(item.objectID, 1)"
                          >
                            <span
                              class="text-white fontszie-smallbtn font-semibold"
                            >
                              REJECTED</span
                            >
                          </div>
                        </div>
                      </div>
                    </template>
                    <!-- SLOT: ACTION BUTTONS -->
                    <template slot="action-buttons">
                      <div
                        class="lg:flex md:flex"
                        v-if="
                          category == 'Membership Request' &&
                          item.RequestStatus == 'Pending'
                        "
                      >
                        <div
                          class="item-view-primary-action-btn bg-success cust-small-btn mr-3 flex flex-grow items-center justify-center cursor-pointer"
                          @click="acceptMembershipRequest(item)"
                        >
                          <span class="text-white fontszie-smallbtn">
                            Approve</span
                          >
                        </div>
                        <div
                          class="item-view-primary-action-btn bg-danger cust-small-btn mr-3 flex flex-grow items-center justify-center cursor-pointer"
                          @click="rejectMembershipRequest(item)"
                        >
                          <span class="text-white fontszie-smallbtn">
                            Reject</span
                          >
                        </div>
                      </div>
                      <div class="flex" v-if="category == 'Invited users'">
                        <div
                          v-if="check('admin')"
                          class="item-view-primary-action-btn bg-success cust-small-btn mr-3 flex flex-grow items-center justify-center cursor-pointer"
                          @click.stop="openInviteSidebar(item)"
                        >
                          <span class="text-white fontszie-smallbtn">
                            SEND INVITE</span
                          >
                        </div>
                        <div
                          v-if="check('admin')"
                          class="item-view-primary-action-btn bg-danger cust-small-btn mr-3 flex flex-grow items-center justify-center cursor-pointer"
                          @click.stop="deleteInvitedUserAlert(item)"
                        >
                          <span class="text-white fontszie-smallbtn">
                            DELETE INVITE</span
                          >
                        </div>
                      </div>
                      <div class="flex lg:ml-3" v-if="item.status == 0">
                        <div
                          v-if="check('superadmin') && item.status == 0"
                          class="item-view-primary-action-btn bg-success cust-small-btn mr-3 flex flex-grow items-center justify-center cursor-pointer"
                          @click="approveRejectedUserAlert(item.objectID, 1)"
                        >
                          <span class="text-white fontszie-smallbtn">
                            Approve</span
                          >
                        </div>
                        <div
                          v-if="check('superadmin') && item.status == 0"
                          class="item-view-primary-action-btn bg-danger cust-small-btn mr-3 flex flex-grow items-center justify-center cursor-pointer"
                          @click="approveRejectedUserAlert(item.objectID, 2)"
                        >
                          <span class="text-white fontszie-smallbtn">
                            Reject</span
                          >
                        </div>
                      </div>
                      <div class="flex lg:ml-3" v-if="item.status == 1">
                        <div
                          v-if="check('admin') && !item.isOwner"
                          :class="{
                            'bg-success': item.blockStatus,
                            'bg-danger': !item.blockStatus,
                          }"
                          class="item-view-primary-action-btn cust-small-btn flex flex-grow items-center justify-center cursor-pointer"
                          @click="
                            updateUserBlockStatus(
                              item.objectID,
                              item.blockStatus,
                              item.name
                            )
                          "
                        >
                          <span class="text-white fontszie-smallbtn">
                            {{ item.blockStatus ? "UNBLOCK" : "BLOCK" }}
                          </span>
                        </div>
                        <div
                          v-if="
                            check('superadmin') &&
                            !item.isNormalUser &&
                            !item.isOwner
                          "
                          :class="{
                            'bg-success': !item.isManager,
                            'bg-danger': item.isManager,
                          }"
                          class="item-view-primary-action-btn cust-small-btn ml-3 flex flex-grow items-center justify-center cursor-pointer"
                          @click="
                            updateManagerStatus(
                              item.UID,
                              item.name,
                              item.isManager
                            )
                          "
                        >
                          <span class="text-white fontszie-smallbtn">
                            {{
                              item.isManager
                                ? "REMOVE COMMUNITY MANAGER"
                                : "MAKE COMMUNITY MANAGER"
                            }}</span
                          >
                        </div>
                        <div
                          v-if="
                            check('admin') &&
                            !item.isOwner &&
                            !check('superadmin')
                          "
                          class="item-view-primary-action-btn bg-success cust-small-btn ml-3 flex flex-grow items-center justify-center cursor-pointer"
                          @click="
                            () => {
                              popupActive = true;
                              ldaperror = '';
                              selecteduser.ldapid = '';
                              (selecteduser.UID = item.UID),
                                (selecteduser.name = item.name);
                              selecteduser.mobile = item.mobile;
                              selecteduser.newmobile = item.mobile;
                              selecteduser.email = item.email;
                              if (item.isOwner) {
                                selecteduser.role = 'Owner';
                                selecteduser.newrole = 'Owner';
                              } else if (item.isManager) {
                                selecteduser.role = 'community-manager';
                                selecteduser.newrole = 'community-manager';
                              } else if (item.isNormalUser) {
                                selecteduser.role = 'Reader';
                                selecteduser.newrole = 'Reader';
                              } else if (item.status != 0) {
                                selecteduser.role = 'channel-admin';
                                selecteduser.newrole = 'channel-admin';
                              }
                            }
                          "
                        >
                          <span class="text-white fontszie-smallbtn">
                            Edit
                          </span>
                        </div>
                      </div>
                    </template>

                    <template
                      slot="upload-date"
                      v-if="category == 'Invited users'"
                    >
                      <p
                        class="w-full lg:text-right text-start lg:mt-0 mt-2 fsize12"
                      >
                        Uploaded on {{ item.uploadedTime }}
                      </p>
                    </template>
                    <template
                      slot="channel-list"
                      v-if="item.status == 1 && check('admin')"
                    >
                      <p class="item-name font-semibold fsize12 mb-4 mt-5">
                        List of Channels
                      </p>
                      <div class="flex flex-wrap gap-1">
                        <div
                          class="cust-addmore"
                          v-show="
                            !item.showNotUserGroups &&
                            item.notUserGroups.length > 0
                          "
                        >
                          <vs-button
                            @click="item.showNotUserGroups = true"
                            icon-pack="feather"
                            icon="icon-plus"
                            color="warning"
                            size="small"
                            class="addmore-btn"
                            >Add to Channel
                          </vs-button>
                        </div>
                        <div
                          v-for="userGroups of item.userGroups"
                          :key="userGroups.groupId"
                        >
                          <vs-chip v-if="userGroups.group_type_id == null">
                            <h6 class="item-name cust-channel-cs">
                              {{ userGroups.group_name }}
                            </h6>
                          </vs-chip>
                          <vs-chip
                            v-else
                            @click="
                              removeUserFromGroupAlert(item.UID, userGroups)
                            "
                            closable
                            close-icon="close"
                          >
                            <h6 class="item-name cust-channel-cs">
                              {{ userGroups.group_name }}
                            </h6>
                          </vs-chip>
                        </div>
                      </div>
                      <div class="cust-serach-drop flex">
                        <vs-select
                          v-if="
                            item.showNotUserGroups &&
                            item.notUserGroups.length > 0
                          "
                          autocomplete
                          class="selectExample"
                          label="Select Channel"
                          label-placeholder="Select Channel"
                          v-model="item.selectedGroup"
                        >
                          <vs-select-item
                            :key="index"
                            :value="item.value"
                            :text="item.label"
                            v-for="(item, index) in item.notUserGroups"
                          />
                        </vs-select>
                        <p
                          v-if="
                            item.notUserGroups.length == 0 &&
                            !item.groupsLoading
                          "
                        >
                          User is part of all channels
                        </p>
                        <div class="ml-2 mt-7px" v-show="item.selectedGroup">
                          <vs-button
                            @click="addUserToGroup(item)"
                            color="warning"
                            size="small"
                            class="cust-fontsize"
                          >
                            Add
                          </vs-button>
                        </div>
                      </div>
                    </template>
                  </item-list-view>
                </div>
              </template>
            </div>
            <!-- </ais-hits> -->

            <!-- PAGINATION -->
            <vs-pagination
              :total="membersPageCount"
              v-model="membersPage"
            ></vs-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- </ais-instant-search> -->
  </div>
</template>

<script>
import {
  AisClearRefinements,
  AisConfigure,
  AisHierarchicalMenu,
  AisHits,
  AisInstantSearch,
  AisNumericMenu,
  AisPagination,
  AisRangeInput,
  AisRatingMenu,
  AisRefinementList,
  AisSearchBox,
  AisSortBy,
  AisStats,
} from "vue-instantsearch";
import algoliasearch from "algoliasearch/lite";
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const userRepository = RepositoryFactory.get("user");
const groupsRepository = RepositoryFactory.get("groups");
const dashboardRepository = RepositoryFactory.get("dashboard");
const notificationRepository = RepositoryFactory.get("notifications");
const profileRepository = RepositoryFactory.get("profile");
import jwt from "jsonwebtoken";
import vSelect from "vue-select";
import notificationsMixin from "@/Core/mixins/notificationsMixin";
import validationMixin from "@/Core/mixins/validationMixin";
import InvitedMembersSelectSidebar from "./Invite Members/InvitedMembersSelectSidebar";
import SendInvitationSidebar from "./Invite Members/SendInvitationSidebar";
import { ClientRepositoryFactory } from "@/Client/Kotak/kotakapicall/ClientRepositoryFactory";

const LDAPRepository = ClientRepositoryFactory.get("LDAP");
export default {
  mixins: [notificationsMixin, validationMixin],
  components: {
    ItemGridView: () => import("./MemberGridView.vue"),
    ItemListView: () => import("./MemberListView.vue"),
    AisClearRefinements,
    AisConfigure,
    AisHierarchicalMenu,
    AisHits,
    AisInstantSearch,
    AisNumericMenu,
    AisPagination,
    AisRangeInput,
    AisRatingMenu,
    AisRefinementList,
    AisSearchBox,
    AisSortBy,
    AisStats,
    "v-select": vSelect,
    InvitedMembersSelectSidebar,
    SendInvitationSidebar,
  },
  data() {
    return {
      searchClient: algoliasearch(
        "latency",
        "6be0576ff61c053d5f9a3225e2a90f76"
      ),
      popupActive: false,
      select1Normal: "",
      select1: 3,
      select2: 7,
      select3: 2,
      options1: [
        { text: "IT", value: 0 },
        { text: "Blade Runner", value: 2 },
        { text: "Thor Ragnarok", value: 3 },
      ],
      // Filter Sidebar
      isFilterSidebarActive: true,
      clickNotClose: true,
      currentItemView: "item-list-view",
      numericItems: [
        { label: "All" },
        { label: "<= $10", end: 10 },
        { label: "$10 - $100", start: 10, end: 100 },
        { label: "$100 - $500", start: 100, end: 500 },
        { label: ">= $500", start: 500 },
      ],
      algoliaCategories: [
        "hierarchicalCategories.lvl0",
        "hierarchicalCategories.lvl1",
        "hierarchicalCategories.lvl2",
        "hierarchicalCategories.lvl3",
      ],
      myItems: [],
      myItemsResults: [],
      myItemsFiltered: [],
      myGroups: [],
      token: {},
      memberPage: 1,
      memberOffset: 0,
      memberLimit: 3000,
      pageSize: 10,
      membersPage: 1,
      searchTerm: "",
      inviteUserPopupActive: false,
      isDeleted: false,
      isDeleted2: false,
      showdropdown: false,
      category: "",
      categories: [
        {
          value: "Active",
          text: "Active",
        },
        {
          value: "Pending",
          text: "Pending",
        },
        {
          value: "Disabled",
          text: "Disabled",
        },
        {
          value: "Community managers",
          text: "Community managers",
        },
        {
          value: "Readers",
          text: "Readers",
        },
        {
          value: "Editors",
          text: "Channel Admins",
        },
        {
          value: "Blocked users",
          text: "Blocked users",
        },
        {
          value: "Invited users",
          text: "Invited users",
        },
        {
          value: "Membership Request",
          text: "Membership Request",
        },
      ],
      invitedUsers: [],
      membershipRequest: [],
      inviteall: false,
      inviteSingleUser: false,
      invitedUser: {},
      openInvitedMembersSidebar: false,
      ldaperror: "",
      selecteduser: {
        name: "",
        mobile: "",
        newmobile: "",
        email: "",
        role: "",
        newrole: "",
        ldapid: "",
        UID: "",
      },
      initialRole: "admin",
    };
  },
  computed: {
    toValue() {
      return (value, range) => [
        value.min !== null ? value.min : range.min,
        value.max !== null ? value.max : range.max,
      ];
    },

    // GRID VIEW
    isInCart() {
      return (itemId) => this.$store.getters["eCommerce/isInCart"](itemId);
    },
    isInWishList() {
      return (itemId) => this.$store.getters["eCommerce/isInWishList"](itemId);
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    magittUrl() {
      return this.baseUrlMagitt;
    },
    totalResults() {
      if (this.searchTerm.length == 0 && this.category == "") {
        return this.myItems.length;
      } else if (this.searchTerm.length == 0 && this.category != "") {
        if (this.category == "Invited users") {
          return this.invitedUsers.length;
        } else if (this.category == "Membership Request") {
          return this.membershipRequest.length;
        } else {
          return this.myItemsFiltered.length;
        }
      } else {
        return this.myItemsResults.length;
      }
    },
    membersPageCount() {
      if (this.searchTerm.length == 0 && this.category == "") {
        let l = this.myItems.length,
          s = this.pageSize;
        return Math.ceil(l / s);
      } else if (this.searchTerm.length == 0 && this.category != "") {
        if (this.category == "Invited users") {
          let l = this.invitedUsers.length,
            s = this.pageSize;
          return Math.ceil(l / s);
        } else if (this.category == "Membership Request") {
          let l = this.membershipRequest.length,
            s = this.pageSize;
          return Math.ceil(l / s);
        } else {
          let l = this.myItemsFiltered.length,
            s = this.pageSize;
          return Math.ceil(l / s);
        }
      } else {
        let l = this.myItemsResults.length,
          s = this.pageSize;
        return Math.ceil(l / s);
      }
    },
    members() {
      const start = (this.membersPage - 1) * this.pageSize,
        end = start + this.pageSize;
      if (this.searchTerm.length == 0 && this.category == "") {
        return this.myItems.slice(start, end);
      } else if (this.searchTerm.length == 0 && this.category != "") {
        if (this.category == "Invited users") {
          return this.invitedUsers.slice(start, end);
        } else if (this.category == "Membership Request") {
          return this.membershipRequest.slice(start, end);
        } else {
          return this.myItemsFiltered.slice(start, end);
        }
      } else {
        return this.myItemsResults.slice(start, end);
      }
    },
  },
  watch: {
    windowWidth() {
      this.setSidebarWidth();
    },
    searchTerm(val) {
      this.scriptValidation(val, "searchTerm");
    },
  },
  methods: {
    validateldap() {
      let regex = /[^A-Z0-9.]/gi;
      this.selecteduser.ldapid = this.selecteduser.ldapid.replace(regex, "");
    },

    async mapLDAP() {
      const payload = {
        mobile: this.selecteduser.mobile,
        empId: this.selecteduser.ldapid,
      };
      const res = await LDAPRepository.mapLDAP(payload);
      return res;
    },
    async updateuserinfo() {
      if (
        this.selecteduser.ldapid == "" &&
        this.selecteduser.newrole !== "Reader"
      ) {
        this.ldaperror = "LDAP ID is required";
        return 0;
      }
      if (
        this.selecteduser.ldapid.length < 8 &&
        this.selecteduser.newrole !== "Reader"
      ) {
        this.ldaperror = "LDAP should be grater than 7";
        return 0;
      }

      let testJwt = jwt.decode(localStorage.getItem("token"));
      let payload = {
        userData: {
          mobile: this.selecteduser.mobile,
          role: this.selecteduser.newrole,
          ldapid: this.selecteduser.ldapid,
          request_by: testJwt.username,
        },
        communityData: {
          communityName: currentCommunityName.communityName,
          uniqueId: localStorage.getItem("biri"),
        },
      };
      // let checkldap = await profileRepository.checkldap(payload);
      // console.log(checkldap);
      // if (checkldap.data.length == 0 || checkldap.data.ResponseCode == 100) {
      let result = await profileRepository.sendRequestRoleChange(payload);
      if (result.data.ResponseCode != 100) {
        this.$vs.notify({
          title: "Error",
          text: result.data.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        return 0;
      }
      if (result.data.ResponseCode == 100) {
        this.popupActive = false;
        this.$vs.notify({
          title: "Success",
          text: result.data.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
        return 0;
      }
      // } else {
      //   this.$vs.notify({
      //     title: "Error",
      //     text: checkldap.data.message,
      //     iconPack: "feather",
      //     icon: "icon-alert-circle",
      //     color: "danger",
      //   });
      //   return 0;
      // }
    },
    removeFilter() {
      this.membersPage = 1;
      this.category = "";
    },
    filterUsers() {
      this.filterUser().then(() => {
        this.myItemsFiltered.length > 0 ? this.showPage1() : 0;
      });
    },
    async filterUser() {
      if (this.category == "Active") {
        this.myItemsFiltered = this.myItems.filter((x) => x.status == 1);
      }
      if (this.category == "Disabled") {
        this.myItemsFiltered = this.myItems.filter((x) => x.status == 2);
      }
      if (this.category == "Community managers") {
        this.myItemsFiltered = this.myItems.filter((x) => x.isManager);
      }
      if (this.category == "Readers") {
        this.myItemsFiltered = this.myItems.filter((x) => x.isNormalUser);
      }
      if (this.category == "Blocked users") {
        this.myItemsFiltered = this.myItems.filter((x) => x.blockStatus);
      }
      if (this.category == "Pending") {
        this.myItemsFiltered = this.myItems.filter((x) => x.status == 0);
      }
      if (this.category == "Editors") {
        this.myItemsFiltered = this.myItems.filter(
          (x) => !x.isManager && !x.isNormalUser && x.status == 1
        );
      }
      if (this.category == "Invited users") {
        this.getInvitedUsers();
      }
      if (this.category == "Membership Request") {
        this.getRequest();
      }
    },
    showPage1() {
      this.membersPage = 1;
    },
    showApprove(item) {
      item.showApprove = true;
    },
    setSidebarWidth() {
      if (this.windowWidth < 992) {
        this.isFilterSidebarActive = this.clickNotClose = false;
      } else {
        this.isFilterSidebarActive = this.clickNotClose = true;
      }
    },

    // GRID VIEW - ACTIONS
    toggleFilterSidebar() {
      if (this.clickNotClose) return;
      this.isFilterSidebarActive = !this.isFilterSidebarActive;
    },
    toggleItemInWishList(item) {
      this.$store.dispatch("eCommerce/toggleItemInWishList", item);
    },
    additemInCart(item) {
      this.$store.dispatch("eCommerce/additemInCart", item);
    },
    cartButtonClicked(item) {
      this.isInCart(item.objectID)
        ? this.$router.push("/apps/eCommerce/checkout").catch(() => {})
        : this.additemInCart(item);
    },
    async showAllMembers() {
      let obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        userData: {
          userID: this.token.uuid,
        },
        paging: {
          limit: this.memberLimit,
          offset: this.memberOffset,
        },
      };

      const { data } = await userRepository.getAllUsers(obj);
      if (data) {
        let records = [];
        let i = 0;
        data.forEach((element) => {
          records.push({
            objectID: element.usersDetails_UniqueId,
            UID: element.usersDetailsId,
            name: element.usersDetails_UserName,
            image:
              element.usersDetails_UserImage != null
                ? this.magittUrl + element.usersDetails_UserImage
                : null,
            mobile: element.usersDetails_Mobile,
            email: element.usersDetails_Email,
            status: element.usersDetails_Status,
            showApprove: false,
            blockStatus: element.usersDetails_BlockStatus == 1 ? true : false,
            isManager: element.communityManager_UserId != null ? true : false,
            isOwner: element.communityManager_Is_owner == 1 ? true : false,
            isNormalUser: element.usersDetails_Password == null ? true : false,
            groupsLoading: true,
            userGroups: [],
            notUserGroups: [],
            showNotUserGroups: false,
            selectedGroup: null,
            profileDetails: [],
          });
          this.getGroupsForDashboard(element.usersDetailsId);
          this.getUserDetails(element.usersDetailsId);
          i++;
          if (data.length == i) {
            if (this.check("admin")) {
              this.myItems = records;
            } else {
              this.myItems = records.filter((x) => !x.blockStatus);
            }
          }
        });
        // data.forEach(element => {
        //   this.users.push(element);
        // });
      }
    },
    async getGroupList() {
      var obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        groupData: {
          userId: this.token.userID,
        },
      };
      const { data } = await groupsRepository.getGroupsOfUser(obj);

      if (data) {
        let records = [];
        let i = 0;
        data.forEach((element) => {
          records.push({
            value: element.group_UniqueId,
            label: element.group_name,
          });
          i++;
          if (data.length == i) {
            this.myGroups = records;
          }
        });
        // data.forEach(element => {
        //   element.isUserGroup = true;
        //   this.GroupList.push(element);
        // });
        // self.GroupList=data;
      }
    },
    showProfile(userid) {
      this.$store.state.memberId = userid;
      this.$router.push("/community-user-profile").catch(() => {});
    },
    resetSearchTerm() {
      this.searchTerm = "";
    },
    searchResults() {
      if (this.searchTerm.length > 1) {
        if (this.category == "Invited users") {
          this.fuzzySearch(this.invitedUsers);
        } else {
          this.fuzzySearch(this.myItems);
        }
      }
    },
    fuzzySearch(dataset) {
      const regex = new RegExp(`.*${this.searchTerm}.*`, "i");
      this.myItemsResults = dataset.filter(({ name }) => name.match(regex));
      // this.$search(this.searchTerm, dataset, { keys: ["name"] }).then(
      //   (results) => {
      //     this.membersPage = 1;
      //     this.myItemsResults = results;
      //   }
      // );
    },
    async updateUserStatus(userId, status) {
      this.$vs.loading();

      var obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        status: status,
        uuid: userId,
      };
      const { data } = await userRepository.changeUserStatus(obj);
      if (data) {
        this.$vs.loading.close();
        const index = this.myItems.findIndex((x) => x.objectID === userId);
        this.myItems[index].status = status;
      }
    },
    async updateUserBlockStatus(userId, blockStatus, username) {
      this.$vs.loading();
      let status = 0;
      if (blockStatus) {
        status = 0;
      } else {
        status = 1;
      }
      var obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        status: status,
        uuid: userId,
      };
      const { data } = await userRepository.changeUserBlockStatus(obj);
      if (data) {
        this.$vs.loading.close();
        const index = this.myItems.findIndex((x) => x.objectID === userId);
        this.myItems[index].blockStatus = status == 1 ? true : false;

        if (this.myItems[index].blockStatus) {
          this.blockNotification(userId, username, "blocked");
        } else {
          this.blockNotification(userId, username, "unblocked");
        }
      }
    },
    blockNotification(userId, username, status) {
      this.insertNotificationToCommunityManagers(
        `has ${status} ${username}`,
        "User",
        "User Blocked",
        userId
      );
    },
    updateManagerStatus(UID, name, isManager) {
      if (isManager) {
        this.removeCommunityManager(UID);
      } else {
        this.makeCommunityManager(UID, name);
      }
    },
    async makeCommunityManager(UID, name) {
      this.$vs.loading();
      let obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        userData: {
          userId: UID,
          ownerName: name,
        },
      };

      const { data } = await userRepository.makeCommunityManager(obj);
      if (data) {
        this.$vs.loading.close();
        const index = this.myItems.findIndex((x) => x.UID === UID);
        this.myItems[index].isManager = true;
      }
    },
    async removeCommunityManager(UID) {
      this.$vs.loading();
      let obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        userData: {
          userId: UID,
        },
      };

      const { data } = await userRepository.removeCommunityManager(obj);
      if (data) {
        this.$vs.loading.close();
        const index = this.myItems.findIndex((x) => x.UID === UID);
        this.myItems[index].isManager = false;
      }
    },
    async getGroupsForDashboard(userId) {
      var obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        userData: {
          userId: userId,
        },
      };
      const { data } = await dashboardRepository.getGroupsForDashboard(obj);
      const index = this.myItems.findIndex((x) => x.UID === userId);
      this.myItems[index].userGroups = data;
      this.getPrivateGroupList(userId);
    },
    async getPrivateGroupList(userId) {
      var obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        groupData: {
          userId: userId,
        },
      };
      const { data } = await groupsRepository.getPrivateGroups(obj);
      if (data) {
        const index = this.myItems.findIndex((x) => x.UID === userId);
        data.forEach((element) => {
          this.myItems[index].notUserGroups.push({
            label: element.group_name,
            value: element.groupId,
            postingRight: element.group_all_time_group,
            group_type_id: element.group_type_id,
          });
        });
        this.myItems[index].groupsLoading = false;
        this.getSystemGroups(userId);
      }
    },
    async getSystemGroups(userId) {
      var obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        groupData: {
          userId: userId,
        },
      };
      const { data } = await groupsRepository.getSystemGroups(obj);
      if (data) {
        const index = this.myItems.findIndex((x) => x.UID === userId);
        data.forEach((element) => {
          this.myItems[index].notUserGroups.push({
            label: element.group_name,
            value: element.groupId,
            postingRight: element.group_all_time_group,
            group_type_id: element.group_type_id,
          });
        });
      }
    },
    async addUserToGroup(item) {
      let obj = {
        invitationData: {
          groupId: item.selectedGroup,
          inviterUserId: this.token.userID,
          ownerName: this.token.username,
          status:
            item.notUserGroups[
              item.notUserGroups.findIndex((x) => x.value == item.selectedGroup)
            ].postingRight,
        },
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        userData: [item.UID],
      };

      const { data } = await groupsRepository.inviteUserToGroup(obj);
      if (data.ResponseCode == 100) {
        this.$vs.loading.close();

        const index = item.notUserGroups.findIndex(
          (x) => x.value == item.selectedGroup
        );

        const notUserGroupLabel = item.notUserGroups[index].label;

        const notUserGroupType = item.notUserGroups[index].group_type_id;

        const notUserPostingRight = item.notUserGroups[index].postingRight;

        this.insertInvitationNotification(
          item.selectedGroup,
          [item.objectID],
          notUserGroupLabel
        );

        item.userGroups.push({
          groupId: item.selectedGroup,
          group_name: notUserGroupLabel,
          group_type_id: notUserGroupType,
          postingRight: notUserPostingRight,
        });

        this.$swal("", `User added to ${notUserGroupLabel} channel`, "success");

        item.notUserGroups.splice(index, 1);

        item.selectedGroup = null;
      }
    },
    async insertInvitationNotification(groupId, userId, groupName) {
      if (this.token.uuid != userId) {
        let payload = {
          notificationData: {
            initiaterId: this.token.uuid,
            initiaterName: this.token.username,
            initiaterImage: this.token.userImage,
            recepientId: userId,
            recepientType: 1,
            notificationMessage: `has added you to ${groupName}`,
            notificationTitle: "Group",
            notificationEntityType: "Group Invitation",
            notificationEntityId: groupId,
            notificationReadStatus: 0,
          },
          communityData: {
            communityName: currentCommunityName.communityName,
          },
        };
        await notificationRepository.addNotification(payload);
      }
    },
    removeUserFromGroupAlert(userId, group) {
      this.$swal({
        title: "Are you sure?",
        text: `User will be removed from ${group.group_name} channel`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.removeUserfromGroup(userId, group);
          this.addRemovedGroupToGroupList(userId, group);
        }
      });
    },
    addRemovedGroupToGroupList(userId, group) {
      const index = this.myItems.findIndex((x) => x.UID === userId);
      this.myItems[index].notUserGroups.push({
        label: group.group_name,
        value: group.groupId,
        postingRight: group.postingRight,
        group_type_id: group.group_type_id,
      });
    },
    async removeUserfromGroup(userId, group) {
      let obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        groupData: {
          groupId: group.groupId,
          userId: userId,
        },
      };

      const { data } = await groupsRepository.leaveGroup(obj);

      if (data) {
        const index = this.myItems.findIndex((x) => x.UID === userId);
        const userGroups = this.myItems[index].userGroups;
        this.myItems[index].userGroups.splice(
          userGroups.findIndex((x) => x.groupId === group.groupId),
          1
        );
        this.$swal(
          "Removed!",
          `User removed from ${group.group_name} channel`,
          "success"
        );
      }
    },
    async getUserDetails(userID) {
      ////debugger;
      let payload = {};

      payload = {
        userData: {
          id: userID,
        },
        communityData: {
          communityName: currentCommunityName.communityName,
        },
      };

      const { data } = await profileRepository.getAllUserData(payload);

      if (data.length > 0) {
        const index = this.myItems.findIndex((x) => x.UID === userID);
        this.myItems[index].profileDetails = data;
      }
    },
    approveRejectedUserAlert(userId, status) {
      this.$swal({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.updateUserStatus(userId, status);
        }
      });
    },
    acceptMembershipRequest(item) {
      this.$swal({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.value) {
          let payload = {
            userData: {
              ldap: item.ldapid,
            },
            communityData: {
              communityName: currentCommunityName.communityName,
              uniqueId: localStorage.getItem("biri"),
            },
          };

          let response = await profileRepository.acceptRequestRoleChange(
            payload
          );
          if (response.data.ResponseCode != 100) {
            this.$vs.notify({
              title: "Error",
              text: response.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
            return 0;
          }
          if (response.data.ResponseCode == 100) {
            this.removeFilter();
            this.$vs.notify({
              title: "Success",
              text: response.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
            if (item.role == "Reader") {
              const index = this.myItems.findIndex((x) => x.UID === item.UID);
              this.myItems[index].isManager = false;
              this.myItems[index].isNormalUser = true;
              this.myItems[index].isOwner = false;
            }
            if (item.role == "community-manager") {
              const index = this.myItems.findIndex((x) => x.UID === item.UID);
              this.myItems[index].isManager = true;
              this.myItems[index].isNormalUser = false;
              this.myItems[index].isOwner = false;
            }
            if (item.role == "channel-admin") {
              const index = this.myItems.findIndex((x) => x.UID === item.UID);
              this.myItems[index].isManager = false;
              this.myItems[index].isNormalUser = false;
              this.myItems[index].status = 1;
            }
            return 0;
          }
        }
      });
    },
    rejectMembershipRequest(item) {
      this.$swal({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.value) {
          let payload = {
            userData: {
              ldap: item.ldapid,
            },
            communityData: {
              communityName: currentCommunityName.communityName,
              uniqueId: localStorage.getItem("biri"),
            },
          };

          let response = await profileRepository.rejectRequestRoleChange(
            payload
          );
          if (response.data.ResponseCode != 100) {
            this.$vs.notify({
              title: "Error",
              text: response.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
            return 0;
          }
          if (response.data.ResponseCode == 100) {
            this.removeFilter();
            this.$vs.notify({
              title: "Success",
              text: response.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
            return 0;
          }
        }
      });
    },
    async getInvitedUsers() {
      this.invitedUsers = [];
      this.$vs.loading();
      let payload = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
      };

      const { data } = await userRepository.getExcelInvitedUsers(payload);
      if (data.length > 0) {
        this.$vs.loading.close();
        data.forEach((element) => {
          this.invitedUsers.push({
            UID: element.excel_inviteId,
            name: element.user_name,
            image: null,
            mobile: element.mobile_no,
            email: element.email,
            status: 3,
            showApprove: false,
            blockStatus: false,
            isManager: false,
            isOwner: false,
            isNormalUser: true,
            groupsLoading: false,
            userGroups: [],
            notUserGroups: [],
            showNotUserGroups: false,
            selectedGroup: null,
            profileDetails: [],
            invitationStatus: element.status,
            uploadedTime: new Date(element.modified_date).toLocaleString(
              "en-GB"
            ),
          });
        });
      } else {
        this.$vs.loading.close();
      }
    },
    async getRequest() {
      this.membershipRequest = [];
      this.$vs.loading();
      // let payload = {
      //   communityData: {
      //     communityName: currentCommunityName.communityName,
      //   },
      // };

      const result = await profileRepository.getPendingRequestRoleChange();
      let data = result.data.data;
      if (data && data.length > 0) {
        this.$vs.loading.close();
        data.forEach((element) => {
          this.membershipRequest.push({
            UID: element.UID,
            name: element.name,
            image: null,
            mobile: element.request.mobile,
            email: null,
            ldapid: element.request.ldapid,
            role: element.request.role,
            request_by: element.request.request_by,
            status: 3,
            showApprove: false,
            blockStatus: false,
            isManager:
              element.previous_role == "community-manager" ? true : false,
            isOwner: false,
            isNormalUser: element.previous_role == "Reader" ? true : false,
            groupsLoading: false,
            userGroups: [],
            notUserGroups: [],
            showNotUserGroups: false,
            selectedGroup: null,
            profileDetails: [],
            RequestStatus: element.request.status,
            uploadedTime: new Date(element.request.createdAt).toLocaleString(
              "en-GB"
            ),
          });
        });
      } else {
        this.$vs.loading.close();
      }
    },
    addInvitedMembers(users) {
      this.$store.dispatch(
        "userInvitation/setUsers",
        users.map((el) => ({
          ...el,
          isSelected: false,
        }))
      );
    },
    deleteInvitedUserAlert(invitedUser) {
      this.$swal({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.deleteInvitedUsers(invitedUser);
        }
      });
    },
    async deleteInvitedUsers(invitedUser) {
      this.$vs.loading();
      let payload = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        mobile: invitedUser.mobile,
      };

      const { data } = await userRepository.deleteExcelInvitedUser(payload);

      if (data) {
        this.$vs.loading.close();
        const index = this.invitedUsers.findIndex(
          (x) => x.mobile == invitedUser.mobile
        );
        this.invitedUsers.splice(index, 1);
        this.$swal("", `Invitation Deleted`, "success");
      } else {
        this.$vs.loading.close();
      }
    },
    async addReader(params) {
      this.$vs.loading();
      let payload = {
        communityName: currentCommunityName.communityName,
        mobile: params.mobile,
        username: this.token.username,
        source: "website",
      };

      const { data } = await userRepository.createReader(payload);

      if (data) {
        this.$vs.loading.close();
        const index = this.invitedUsers.findIndex(
          (x) => x.mobile === params.mobile
        );
        this.invitedUsers.splice(index, 1);
      } else {
        this.$vs.loading.close();
      }
    },
    openInviteSidebar(user) {
      this.toggleSendInvitationSidebar(true);
      this.inviteSingleUser = true;
      this.invitedUser = user;
    },

    toggleInvitedMembersSidebar(val = false) {
      this.openInvitedMembersSidebar = val;
    },
    toggleSendInvitationSidebar(val = false) {
      this.inviteall = val;
      if (val === false) {
        this.inviteSingleUser = false;
      }
    },
    closeAllInviteSidebar() {
      this.openInvitedMembersSidebar = false;
      this.inviteall = false;
    },
    inviteAllMembers() {
      this.addInvitedMembers(this.invitedUsers);
      this.toggleInvitedMembersSidebar(true);
    },
    check(role) {
      if (role === "admin") {
        if (this.initialRole == "admin" || this.initialRole == "superadmin") {
          return true;
        }
      } else if (role === "superadmin") {
        if (this.initialRole == "superadmin") {
          return true;
        }
      }
    },
  },
  async created() {
    this.setSidebarWidth();
    let token = jwt.decode(localStorage.getItem("token"));
    let payload = {
      userData: {
        id: token.userID,
      },
      communityData: {
        communityName: currentCommunityName.communityName,
      },
    };
    const { data } = await profileRepository.getAllUserData(payload);
    if (data[0].communityManager_Is_owner == 1) {
      this.initialRole = "superadmin";
    } else if (data[0].communityManager_UserId != null) {
      this.initialRole = "admin";
    } else if (data[0].usersDetails_Password == null) {
      this.initialRole = "normal";
    } else {
      this.initialRole = "editor";
    }
  },
  mounted() {
    let token = localStorage.getItem("token");
    this.token = jwt.decode(token);
    if (this.$router.currentRoute.params.filter) {
      this.category = this.$router.currentRoute.params.filter;
      this.showAllMembers().then(() => {
        this.filterUsers();
      });
    } else {
      this.showAllMembers();
      this.getGroupList();
    }
    // if(this.$router.currentRoute.params.LTN) {
    //   this.addLTNProducts(this.$router.currentRoute.params.LTN)
    // } else {
    //   this.addProducts();
    // }
  },
};
</script>

<style lang="scss">
.cust-serach-drop > div {
  width: 60%;
}
#algolia-instant-search-demo {
  .algolia-header {
    .algolia-filters-label {
      width: calc(260px + 2.4rem);
    }
  }

  #algolia-content-container {
    .vs-sidebar {
      position: relative;
      float: left;
    }
  }

  .algolia-search-input-right-aligned-icon {
    padding: 1rem 1.5rem;
  }

  .algolia-price-slider {
    min-width: unset;
  }

  .item-view-primary-action-btn {
    color: #2c2c2c !important;
    background-color: #f6f6f6;
  }

  .item-view-secondary-action-btn {
    min-width: 50%;
  }
}

.theme-dark {
  #algolia-instant-search-demo {
    #algolia-content-container {
      .vs-sidebar {
        background-color: #10163a;
      }
    }
  }
}

@media (min-width: 992px) {
  .vs-sidebar-rounded {
    .vs-sidebar {
      border-radius: 0.5rem;
    }

    .vs-sidebar--items {
      border-radius: 0.5rem;
    }
  }
}

@media (max-width: 992px) {
  #algolia-content-container {
    .vs-sidebar {
      position: absolute !important;
      float: none !important;
    }
  }
}
</style>
<style>
.EcommerceMainPage_padding {
  padding: 10px;
}
.viewprof-cateegorycard {
  width: 350px !important;
  height: fit-content;
}
.flex-parent-cls {
  display: flex;
}
.bottm-parent-btn {
  white-space: nowrap;
  height: 40px;
}
.fontszie-smallbtn {
  font-size: 0.7em !important;
}
.cust-small-btn {
  padding: 0.5rem 1.5rem;
  border-radius: 20px;
  white-space: nowrap;
  height: 26px;
}
@media (min-width: 1920px) {
  .cust-img-width[data-v-75cd3bae] {
    width: 90% !important;
    height: 100px;
  }
}
.vuesax-app-is-ltr .con-vs-chip {
  margin-right: 10px !important;
}
.cust-chip-t {
  margin-top: -2px;
}
.cust-serach-drop {
  margin-top: 10px;
}
.cust-serachcss {
  width: 60%;
}
.cust-serachcss > div {
  width: 100% !important;
}
h6.item-name.cust-channel-cs {
  padding: 8px;
  font-size: 12px;
  white-space: nowrap;
}
.addmore-btn {
  border-radius: 20px !important;
  height: 30px;
}
.cust-fontsize {
  font-size: 12px !important;
  border-radius: 20px;
  height: 34px;
}
.cust-serach-drop > div > div > .input-select {
  border-radius: 20px !important;
  padding: 8px;
  background: #f0f0f0;
}
.cust-img-width {
  height: 135px !important;
  border-radius: 0.5rem !important;
  width: 90% !important;
}
.mt-7px {
  margin-top: 1.4rem;
}
@media (max-width: 640px) {
  .viewprof-cateegorycard {
    width: 100% !important;
  }
  .mt-7px {
    margin-top: 1.4rem;
  }
}
</style>
