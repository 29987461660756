import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const notificationRepository = RepositoryFactory.get("notifications");
const userRepository = RepositoryFactory.get("user");

import { currentCommunityName } from "@/Core/config/communityDetails.js";

export default {
  data() {
    return {
      communityManagers: []
    };
  },
  methods: {
    async insertNotification(message, title, entityType, entityId, userId) {
      if (this.token.uuid != userId) {
        let payload = {
          notificationData: {
            initiaterId: this.$store.state.user.uuid,
            initiaterName: this.$store.state.user.username,
            initiaterImage: this.$store.state.user.userImage,
            recepientId: userId,
            recepientType: 1,
            notificationMessage: message,
            notificationTitle: title,
            notificationEntityType: entityType,
            notificationEntityId: entityId,
            notificationReadStatus: 0
          },
          communityData: {
            communityName: currentCommunityName.communityName
          }
        };
        await notificationRepository.addNotification(payload);
      }
    },
    async getCommunityManagers() {
      const payload = {
        communityName: currentCommunityName.communityName
      };
      const { data } = await userRepository.getCommunityManagers(payload);

      if (data) {
        this.communityManagers = data.map(x => x.usersDetails_UniqueId);
      }
    },
    insertNotificationToCommunityManagers(
      message,
      title,
      entityType,
      entityId
    ) {
      this.insertNotification(
        message,
        title,
        entityType,
        entityId,
        this.communityManagers
      );
    }
  },
  created() {
    this.getCommunityManagers();
  }
};
